import React from 'react'

import Text from 'atoms/Text'
import { CLR } from 'styles/theme'
import Container from 'atoms/Container'
import CarouselAutoWidth from 'molecules/CarouselAutoWidth'
import PartnersCard from 'molecules/PartnersCard'
import IconArrowPrevious from 'atoms/SvgIcons/IconArrowPrevious.svg'
import IconArrowNext from 'atoms/SvgIcons/IconArrowNext.svg'

const THEME = {
    v1: {
        heading: 'text-2xl xxs:text-3xl text-center relative z-1 -mb-5',
        headingFontWeight: 700,
    },
    v2: {
        heading: 'font-Montserrat text-18-28 md:text-18-34 text-center relative z-1 -mb-7',
        headingFontWeight: 600,
    }
}

const PartnerSection = ({ className = '', heading, list, theme = 'v1', qualification = '', experience = '', practitionerHomepage = false }) => {
    return (
        <div className={`${className} relative`}>
            <Text className='text-18-28 text-gray-900 font-Montserrat font-semibold text-center'>
                {heading}
            </Text>
            <Container type='static' className='relative'>
                {list?.length > 3 && <div className='w-full hidden md:block'>
                    <button className={`partnerArrow--prev absolute -left-2 top-[calc(50%_+_20px)] -translate-y-1/2 rounded-full z-10 group`} data-automation='medicine-left-arrow'>
                        <IconArrowPrevious className='text-gray-900 w-10' />
                    </button>
                    <button className={`partnerArrow--next absolute -right-2 top-[calc(50%_+_20px)] -translate-y-1/2 rounded-full z-10 group`} data-automation='medicine-right-arrow'>
                        <IconArrowNext className='!text-gray-900 w-10' />
                    </button>
                </div>}

                <CarouselAutoWidth
                    className='relative z-9'
                    headingRowClasses='px-4'
                    navigationElement='partner'
                    autoplayDelay={5000}
                    isAutoPlay={true}
                    spaceBetween={2}
                    practitionerHomepage={practitionerHomepage}
                    breakpoints={{
                        0: {
                            slidesPerView: 1.2,
                            slidesPerGroup: 1,
                        },
                        520: {
                            slidesPerView: 2.1,
                            slidesPerGroup: 1,
                        },
                        640: {
                            slidesPerView: 5,
                            slidesPerGroup: 1,
                        },
                    }}
                    list={
                        list?.map((item, index) => (
                            <PartnersCard key={item?.id} className='px-4 pb-2  ml-4 md:mx-2' name={item?.name} userType={item?.userType}
                                avatar={item?.avatar} description={item?.description} theme={theme} qualification={item?.qualification} experience={item?.experience} />
                        ))
                    }
                />
            </Container>
        </div>
    )
}

export default PartnerSection